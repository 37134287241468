<script setup lang="ts">
import { ref, onBeforeMount, watch } from "vue";
import { useRouter } from "vue-router";
import { confirmPasswordReset } from "firebase/auth";
import AuthService from "@/views/pages/Auth/Auth.service";
import { useFirebaseStore, useLoadingService } from "@/stores";
import TextField from "@/shared/components/TextField.vue";
import ButtonBraip from "@/shared/components/ButtonBraip.vue";
import PasswordLevel from "@/shared/components/PasswordLevel.vue";

import ShieldCheck from "@/shared/components/icons/dark/ShieldCheck.vue";

const router = useRouter();

const newPassword = ref("");
const newPasswordConfirm = ref("");
const errorNewPassword = ref("");
const errorNewPasswordConfirm = ref("");
const submitted = ref(false);
const loading = ref(false);
const disabled = ref(false);
const passwordLevel = ref(0);

const firebaseStore = useFirebaseStore();
const oobCode = ref("");
const loadingScreen = useLoadingService();

onBeforeMount(() => {
  oobCode.value = firebaseStore.oobCode;
  firebaseStore.setOobCode("");
  loadingScreen.disabledLoading();
});

watch(newPassword, () => {
  checkPasswordLevel();
});

function checkPasswordLevel() {
  passwordLevel.value = 0;

  const size = newPassword.value.length;
  const lowercase = newPassword.value.match(/[a-z]/gu)?.length || 0;
  const uppercase = newPassword.value.match(/[A-Z]/gu)?.length || 0;
  const numbers = newPassword.value.match(/[\d]/gu)?.length || 0;
  const symbols =
    newPassword.value.match(
      /[\u00C0-\u017F!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~´ºª¨°¹²³ẽǘ£¢¬{}| ]/gu
    )?.length || 0;

  let total = 0;
  if (size >= 8) {
    total += 30;
  }
  if (lowercase) {
    total += lowercase >= 3 ? 15 : 10;
  }
  if (uppercase) {
    total += uppercase >= 2 ? 30 : 10;
  }
  if (numbers) {
    total += numbers >= 2 ? 30 : 10;
  }
  if (symbols) {
    total += symbols >= 2 ? 40 : 20;
  }
  if (!total) {
    passwordLevel.value = 0;
  } else if (total <= 70) {
    passwordLevel.value = 1;
  } else if (total <= 100) {
    passwordLevel.value = 2;
  } else if (total <= 130) {
    passwordLevel.value = 3;
  } else {
    passwordLevel.value = 4;
  }
}

function validPassword() {
  if (!newPassword.value || !newPasswordConfirm.value) {
    if (!newPassword.value) {
      errorNewPassword.value = "O campo é obrigatório.";
    } else {
      errorNewPassword.value = "";
    }
    if (!newPasswordConfirm.value) {
      errorNewPasswordConfirm.value = "O campo é obrigatório.";
    } else {
      errorNewPasswordConfirm.value = "";
    }
  } else if (newPassword.value !== newPasswordConfirm.value) {
    errorNewPassword.value = "As senhas não batem.";
    errorNewPasswordConfirm.value = "";
  } else {
    errorNewPassword.value = "";
    errorNewPasswordConfirm.value = "";
  }
}

async function handleResetPassword() {
  validPassword();
  if (errorNewPassword.value || errorNewPasswordConfirm.value) return;
  loading.value = true;

  confirmPasswordReset(AuthService.auth, oobCode.value, newPassword.value)
    .then(() => {
      submitted.value = true;
      // AlertService.toastSuccess("Senha atualizada.");
    })
    .catch(() => {
      errorNewPassword.value = "Algo deu errado.";
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div
    class="flex min-h-full flex-col bg-primary-600 bg-braip-login bg-cover bg-top bg-no-repeat"
  >
    <main class="flex min-w-fit flex-1 flex-col items-center justify-center">
      <form
        class="mx-4 mt-xxl flex max-w-md flex-col gap-12 rounded-sm bg-white p-10"
        @submit.prevent="handleResetPassword"
        v-if="!submitted"
      >
        <div class="flex flex-col gap-14">
          <div class="flex items-center justify-between">
            <img
              class="w-36"
              src="@/assets/img/logos/logo-pages-horizontal.svg"
              alt="Logo da Braip"
            />
          </div>

          <div class="flex flex-col gap-nano">
            <h1 class="text-dark-100 text-h7 font-poppins font-semibold">
              Crie uma nova senha
            </h1>
            <p class="text-light-700 text-p4">
              Agora é só criar uma senha segura para manter sua conta protegida!
            </p>
          </div>
        </div>
        <div class="flex flex-col gap-xs">
          <div class="flex flex-col gap-xxs">
            <div class="flex flex-col gap-xxxs">
              <TextField
                label="Nova senha"
                id="new-password"
                type="password"
                v-model="newPassword"
                :error-message="errorNewPassword"
                @input="errorNewPassword = ''"
              />
              <TextField
                label="Confirmar nova senha"
                id="confirm-new-password"
                type="password"
                v-model="newPasswordConfirm"
                :error-message="errorNewPasswordConfirm"
                @input="errorNewPasswordConfirm = ''"
              />
            </div>
          </div>
          <PasswordLevel :level="passwordLevel" />
          <div class="flex flex-col items-center gap-xxs">
            <ButtonBraip
              text="Alterar minha senha"
              type="submit"
              class="!justify-center"
              semibold
              full
              :disabled="disabled"
              :loading="loading"
            />
          </div>
        </div>
      </form>
      <div
        class="mx-4 mt-xxl flex max-w-md flex-col gap-12 rounded-sm bg-white p-10"
        v-else
      >
        <div class="flex flex-col gap-14">
          <div class="flex items-center justify-between">
            <img
              class="w-36"
              src="@/assets/img/logos/logo-pages-horizontal.svg"
              alt="Logo da Braip"
            />
          </div>
          <div class="flex justify-center">
            <div class="p-6 bg-linear-purple">
              <ShieldCheck class="fill-primary-600" :size="50" />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-xs">
          <div class="flex flex-col gap-nano">
            <h1
              class="text-dark-100 text-h7 font-poppins font-semibold text-center"
            >
              Sua senha foi alterada com sucesso!
            </h1>
            <p class="text-light-700 text-p4 text-center">
              Tudo pronto! Agora você já pode acessar a sua conta.
            </p>
          </div>
          <ButtonBraip
            text="Acessar"
            type="button"
            class="!justify-center"
            semibold
            full
            :disabled="disabled"
            @click="router.push('/')"
          />
        </div>
      </div>
    </main>
    <footer class="flex w-full justify-center">
      <p class="mx-4 my-xs w-36 sm:w-full text-center text-p6 text-light-0/40">
        © Braip 2024. Todos os direitos reservados
      </p>
    </footer>
  </div>
</template>

<style scoped></style>
